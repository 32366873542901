exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-recalls-js": () => import("./../../../src/pages/product-recalls.js" /* webpackChunkName: "component---src-pages-product-recalls-js" */),
  "component---src-pages-retail-registration-js": () => import("./../../../src/pages/retail-registration.js" /* webpackChunkName: "component---src-pages-retail-registration-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-warranty-registration-js": () => import("./../../../src/pages/warranty-registration.js" /* webpackChunkName: "component---src-pages-warranty-registration-js" */)
}

