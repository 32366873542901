import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import Search from '../assets/images/search.svg'
import Cart from '../assets/images/cart.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    productsToggle: false,
    brandsToggle: false,
    dropdownOpen: false,
    parentDropdownOpen: false,
  }

  _toggleDropdown = (e, dropdownItem) => {
    e.preventDefault()
    this.setState({dropdownOpen: {[dropdownItem]: !this.state.dropdownOpen[dropdownItem]} })
  }

  _toggleParentDropdown = (e, dropdownItem) => {
    e.preventDefault()
    this.setState({parentDropdownOpen: {[dropdownItem]: !this.state.parentDropdownOpen[dropdownItem]} })
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, productsToggle, brandsToggle } = this.state

    const { mainUrl } = this.props.data.site.siteMetadata
    let { mainMenu, products, brands } = this.props.data.wp.siteSettings.settings

    let props = {
      onClick: this._hideOffCanvas,
    }

    return (
      <>
        <header className={`header ${ offCanvas && 'active' }`}>
          <div className="header__inner">
            <div className="header__left">
              <a className="header__logo" href={`${mainUrl}/`}>
                <img src={Logo} alt="Lenoxx" />
              </a>
              <nav className="header__nav">
                <ul>
                  { mainMenu && mainMenu.map((el, i) => (
                    <li key={i}>
                      { el.text === 'Products' &&
                        <>
                          <Link to={el.link}>
                            {el.text}
                            <svg width="7px" height="4px" viewBox="0 0 7 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g className="down-arrow" transform="translate(-479.000000, -43.000000)" fill="#102591">
                                  <g transform="translate(479.299952, 43.000966)">
                                    <path d="M1.0694334,4.9838277 C1.08805961,4.99437483 1.10859098,5 1.12954662,5 C1.15558105,5 1.18119175,4.99109333 1.2025722,4.97468642 L4.94412408,2.11991831 C4.97904815,2.09319888 5,2.04936813 5,2.00226068 C5,1.95538423 4.97904545,1.91132187 4.94412408,1.88460304 L1.2025722,-0.974845213 C1.16277957,-1.00508075 1.11113444,-1.00836165 1.06837679,-0.983048666 C1.02583263,-0.957969692 0.999377177,-0.908514366 1,-0.854842709 L1,4.85727359 C1,4.91047844 1.02668063,4.95922874 1.0694334,4.9838415 L1.0694334,4.9838277 Z" transform="translate(3.000000, 2.000000) rotate(-270.000000) translate(-3.000000, -2.000000) "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </Link>
                          <div className={`sub-nav main-products${productsToggle ? ' active' : ''}`}>
                            <div className="sub-nav__inner">                   
                              <ul className="sub-nav__items">
                                { products.map((menu, m) => (
                                  <li key={m} className="sub-nav__parent">
                                    <Link to={menu.link ? menu.link : '//'}>{menu.title}</Link> 
                                    { menu.menu && menu.menu.length > 0 &&                        
                                      <ul className="grandchild">    
                                        { menu.menu.map((submenu, s) => (                        
                                          <li key={s}><Link to={submenu.link}>{submenu.text}</Link></li>
                                        )) }
                                      </ul>
                                    }
                                  </li>
                                )) }
                              </ul>
                            </div>
                          </div>
                        </>
                      }
                      { el.text === 'Brands' &&
                        <>
                          <Link to={el.link}>
                            {el.text}
                            <svg width="7px" height="4px" viewBox="0 0 7 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g className="down-arrow" transform="translate(-479.000000, -43.000000)" fill="#102591">
                                  <g transform="translate(479.299952, 43.000966)">
                                    <path d="M1.0694334,4.9838277 C1.08805961,4.99437483 1.10859098,5 1.12954662,5 C1.15558105,5 1.18119175,4.99109333 1.2025722,4.97468642 L4.94412408,2.11991831 C4.97904815,2.09319888 5,2.04936813 5,2.00226068 C5,1.95538423 4.97904545,1.91132187 4.94412408,1.88460304 L1.2025722,-0.974845213 C1.16277957,-1.00508075 1.11113444,-1.00836165 1.06837679,-0.983048666 C1.02583263,-0.957969692 0.999377177,-0.908514366 1,-0.854842709 L1,4.85727359 C1,4.91047844 1.02668063,4.95922874 1.0694334,4.9838415 L1.0694334,4.9838277 Z" transform="translate(3.000000, 2.000000) rotate(-270.000000) translate(-3.000000, -2.000000) "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </Link>
                          <div className={`sub-nav main-brands${brandsToggle ? ' active' : ''}`}>
                            <div className="sub-nav__inner">                    
                              <div className="logo-list">
                                <div className="logo-list__list">
                                  { brands.map((brand, b) => ( 
                                    <div className="logo-list__item" key={b}>
                                      <Link to={brand.link}>
                                        <img src={brand.image.localFile?.childImageSharp?.original.src} alt='Lenoxx Electronics' />
                                      </Link>
                                    </div>
                                  )) }
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      { el.text !== 'Products' && el.text !== 'Brands' && <Link to={el.link} {...props}>{el.text}</Link> }
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="header__right">
              <form action={`${mainUrl}/search`} method="get" className="header__search">
                <input type="search" name="q" placeholder="Search Products" />
                <button type="submit">
                  <img src={Search} alt="Search" />
                </button>
              </form>
              <a className="header__cart" href={`${mainUrl}/cart`}>
                <img src={Cart} alt="Cart" />
              </a>
              <div className='header__menu-container'>
                <button className={`header__hamburger ${ offCanvas && 'active' }`} onClick={() => this._toggleOffCanvas()}>
                  <span className='lines'></span>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'active' }`}>
          <div className="off-canvas__inner">
            <form action={`${mainUrl}/search`} method="get" className="header__search">
              <input type="search"
                name="q"
                id="search"
                placeholder="Search Products"
              />
              <button type="submit" className="button">
                <img src={Search} alt="Search" />
              </button>
            </form>
            <nav className="off-canvas__nav" role="navigation">
              <ul>
                { mainMenu && mainMenu.map((el, i) => (
                  <li key={i} className={`${el.text === 'Products' && 'menu-item-has-children'} ${this.state.parentDropdownOpen[i] && 'active'} ${el.text === 'Brands' && 'menu-item-has-children'}`}>
                    { el.text === 'Products' &&
                      <>
                        <Link to={el.link} className="parent" onClick={(e) => this._toggleParentDropdown(e, i)}>
                          {el.text}
                          <svg width="7px" height="4px" viewBox="0 0 7 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g className="down-arrow" transform="translate(-479.000000, -43.000000)" fill="#102591">
                                <g transform="translate(479.299952, 43.000966)">
                                  <path d="M1.0694334,4.9838277 C1.08805961,4.99437483 1.10859098,5 1.12954662,5 C1.15558105,5 1.18119175,4.99109333 1.2025722,4.97468642 L4.94412408,2.11991831 C4.97904815,2.09319888 5,2.04936813 5,2.00226068 C5,1.95538423 4.97904545,1.91132187 4.94412408,1.88460304 L1.2025722,-0.974845213 C1.16277957,-1.00508075 1.11113444,-1.00836165 1.06837679,-0.983048666 C1.02583263,-0.957969692 0.999377177,-0.908514366 1,-0.854842709 L1,4.85727359 C1,4.91047844 1.02668063,4.95922874 1.0694334,4.9838415 L1.0694334,4.9838277 Z" transform="translate(3.000000, 2.000000) rotate(-270.000000) translate(-3.000000, -2.000000) "></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </Link>
                        <ul className="sub-nav__items">
                          { products.map((menu, m) => (
                            <li key={m} className={`sub-nav__parent menu-item-has-children ${this.state.dropdownOpen[m] && 'active'}`}>
                              <a href="#" className="parent" onClick={(e) => this._toggleDropdown(e, m)}>
                                {menu.title}
                              </a>
                              { menu.menu && menu.menu.length > 0 &&                        
                                <ul className="grandchild">    
                                  { menu.menu.map((submenu, s) => (                        
                                    <li key={s}><Link to={submenu.link}>{submenu.text}</Link></li>
                                  )) }
                                </ul>
                              }
                            </li>
                          )) }
                        </ul>
                      </>
                    }
                    { el.text === 'Brands' &&
                      <>
                        <Link to={el.link} className="parent" onClick={(e) => this._toggleParentDropdown(e, i)}>
                          {el.text}
                          <svg width="7px" height="4px" viewBox="0 0 7 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g className="down-arrow" transform="translate(-479.000000, -43.000000)" fill="#102591">
                                <g transform="translate(479.299952, 43.000966)">
                                  <path d="M1.0694334,4.9838277 C1.08805961,4.99437483 1.10859098,5 1.12954662,5 C1.15558105,5 1.18119175,4.99109333 1.2025722,4.97468642 L4.94412408,2.11991831 C4.97904815,2.09319888 5,2.04936813 5,2.00226068 C5,1.95538423 4.97904545,1.91132187 4.94412408,1.88460304 L1.2025722,-0.974845213 C1.16277957,-1.00508075 1.11113444,-1.00836165 1.06837679,-0.983048666 C1.02583263,-0.957969692 0.999377177,-0.908514366 1,-0.854842709 L1,4.85727359 C1,4.91047844 1.02668063,4.95922874 1.0694334,4.9838415 L1.0694334,4.9838277 Z" transform="translate(3.000000, 2.000000) rotate(-270.000000) translate(-3.000000, -2.000000) "></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </Link>
                        <ul className="sub-nav__items">
                          { brands.map((brand, b) => ( 
                            <li className="logo-list__item" key={b}>
                              <Link to={brand.link}>
                                {brand.text}
                              </Link>
                            </li>
                          )) }
                        </ul>
                      </>
                    }
                    { el.text !== 'Products' && el.text !== 'Brands' && <Link to={el.link} {...props}>{el.text}</Link> }
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
