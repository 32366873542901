import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            mainUrl
          }
        }
        wp {
          siteSettings {
            settings {
              mainMenu {
                text
                link
              }
              products {
                title
                link
                menu {
                  text
                  link
                }
              }
              brands {
                image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
                text
                link
              }
              footerColumns {
                title
                menu {
                  text
                  link
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header data={data} />
          <main>{ children }</main>
          <Footer data={data} />
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
