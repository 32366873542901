import React, { Component } from 'react'
import Link from '../utils/link'

import Newsletter from '../assets/images/newsletter.svg'
import Instagram from '../assets/images/instagram-blue.svg'
import Facebook from '../assets/images/facebook-blue.svg'
import Twitter from '../assets/images/twitter-blue.svg'
import Pinterest from '../assets/images/pinterest-blue.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Footer extends Component {

  state = {
    email: '',
    formActive: false,
    success: false
  }

  _showForm() {
    this.setState({ formActive: true })
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  // 2. via `async/await`
  _handleSubmit = e => {
    e.preventDefault()
    let data = encode({
      "form-name": 'newsletter',
      ...this.state
    })
    fetch("https://hooks.zapier.com/hooks/catch/2181815/b272522/?"+data)
    .then((response) => {
      if (response.status === 200) {
        this.setState({success: true})
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const { mainUrl } = this.props.data.site.siteMetadata
    let { footerColumns } = this.props.data.wp.siteSettings.settings
    let { formActive, success } = this.state
    return (
      <footer className="footer">
        <div className="footer__top">
          <div className="footer__inner">
            <div className="footer__newsletter">
              <div className="footer__mail">
                { !formActive && !success &&
                  <div className="mail__cta" onClick={() => this._showForm()}>
                    <img className="mail-ico" src={Newsletter} alt="Newsletter" />
                    <span className="mail">Keep up to date with Lenoxx specials</span>
                  </div>
                }
                { formActive && !success &&
                  <form name='newsletter' className="mail__input">
                    <img className="mail-ico" src={Newsletter} alt="Newsletter" />
                    <input onChange={() => this._handleChange()} className="" placeholder="Enter your email" />
                    <button className="submit" onSubmit={() => this._handleSubmit()}><span className="arrow-ico"></span></button>
                  </form>
                }
                { success &&
                  <div className="mail__success">
                    <span className="tick-ico"></span>
                    <span className="message">Success. Thanks for subscribing.</span>
                  </div>
                }
              </div>
            </div>
            <ul className="footer__social">
              <li>
                <a href="https://www.instagram.com/lenoxxelectronics/" target='_blank' rel='nofollow noopener noreferrer'>
                  <img src={Instagram} alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/LenoxxElectronics" target='_blank' rel='nofollow noopener noreferrer'>
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/LenoxxAustralia" target='_blank' rel='nofollow noopener noreferrer'>
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com.au/lenoxx/" target='_blank' rel='nofollow noopener noreferrer'>
                  <img src={Pinterest} alt="Pinterest" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__inner">
            { footerColumns && footerColumns.map((el, i) => (
              <div className="footer__col" key={i}>
                <h4>{el.title}</h4>
                {el.menu && el.menu.length > 0 &&
                  <ul>
                    { el.menu.map((menu, m) => (
                      <li key={m}>
                        { menu.link && <Link to={menu.link}>{menu.text}</Link> }
                        { !menu.link && <span>{menu.text}</span> }
                      </li>
                    )) }
                  </ul>
                }
              </div>
            )) }
            <div className='footer__row footer__credits'>
              <div className='footer__col'>
                <p>© 2021 Lenoxx Electronics - <br /><Link to={`${mainUrl}/pages/lenoxx-policy`}>Lenoxx Policy</Link> | <Link to={`${mainUrl}/pages/privacy-policy`}>Privacy Policy</Link></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
